.site-top {
  width: 100%;
  height: 40px;
  background: rgba(0,0,0, 0.1);
  position: relative;
  p.helpdesk {
    color: #fff;
    font-family: $lato;
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translate(0, -50%);
    i {
      margin-right: 5px;
    }
    @include mobile {
      left: 50%;
      margin-left: -114px;
    }
  }
}
.logo {
  max-width: 285px;
  display: block;
  margin: 30px auto;
  @include mobile {
    max-width: 205px;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: white !important;
}

.pretty.p-default input:checked ~ .state label[for=rememberMe]:after {
  background-color: #fff !important;
}

.pretty .state label[for=rememberMe]::before {
  top: 50%;
  left: auto;
  right: -35px;
  transform: translateY(-50%);
}
.pretty .state label[for=rememberMe]::after {
  top: -1px;
  left: auto;
  right: -35px;
  // transform: translateY(-50%);
  // transform: scale(0.9);
}
.pretty .state label[for=rememberMe] {
  text-indent: 0;
  transform: scale(0.9);
}

.login-logo{
  width: 200px;
  height: auto;
  display: block;
  margin: 30px auto;
}

.login-page {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    color: #fff;
    font-family: $lato;
    text-align: center;
    font-size: 45px;
    font-weight: 300;
    margin-top: 0;
    @include tablet {
      font-size: 34px;
    }
    @include mobile {
      font-size: 26px;
    }
  }
  h4 {
    color: #fff;
    font-family: $lato;
    text-align: center;
    font-size: 32px;
    font-weight: 300;
    text-transform: uppercase;
    @include tablet {
      font-size: 34px;
    }
    @include mobile {
      font-size: 26px;
    }
  }
  .login-box {
    width: 40%;
    max-width: 600px;
    min-height: 445px;
    display: block;
    margin: 30px auto;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    @include white-blur;
    // @include notebook {
    //   width: 80%;
    // }
    @include ipad {
      width: 70%;
    }
    @include mobile {
      width: 100%;
    }
    .inner {
      padding: 20px 40px;
      position: relative;
      z-index: 2;
      width: 100%;
      @include mobile {
        padding: 20px;
      }
      .submitButtonWrapper{
        margin-top: 0 !important;
      }
      .input-group{
        padding: 0 !important;
      }
    h2 {
      color: #fff;
      font-family: $lato;
      text-align: center;
      font-size: 34px;
      font-weight: 300;
      }

      form {
        padding-top: 10px;
        .input {
          width: 100%;
          outline: none;
          color: #fff;
          font-family: $lato;
          font-weight: 300;
        }
        input[type="email"] {
        width: 100%;
        color: #fff!important;
        font-family: $lato;
        font-weight: 300;
        }
        input[type="password"] {
        width: 100%;

        color: #fff!important;
        font-family: $lato;
        font-weight: 300;
        }
      > div {
        text-align: right;
        padding: 5px 0;
      }
      a {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @include checkbox;
      .squaredThree {
        width: 100%;
        margin-top: 0;
        text-align: left;
        label {
          top:10px;
          width: 14px;
          height: 14px;
          left: 120px;
        }
        p {
          color: #fff;
          font-size: 14px;
        }
      }
      // label {color: #fff;}
        .icon-holder {
          position: absolute;
          color: #fff;
          width: 50px;
          height: 50px;
          text-align: center;
          padding-top: 13px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        button[type="submit"] {
            background: rgba($brown, $opacity3);
            border: 1px solid #ccc;
            outline: none;
            text-align: center;
            width: 100%;
            text-transform: uppercase;
            color: #fff;
            border-radius: 5px;
            height: 50px;
            cursor: pointer;
            font-weight: 400;
        }
      }
    }
  }
}
footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  width: 100%;
  z-index: 1;
p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 300;
}
}
.login-form{
  width: 100%;
}
@-webkit-keyframes autofill {
  to {
      color: white;
      background: transparent;
  }
}
@keyframes autofill {
  to {
      color: white;
      background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
input:-webkit-autofill + label{
    transform: translate(0, 1.5px) scale(0.75)!important;
    transform-origin: top left!important;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms!important;
    color: 'red';
  }

