$lato: "Lato", sans-serif;
$brown: #5e5e5e;
$brown2: #272727;
$light-brown: #59616b;
$blue-hover: #00aeef;
$opacity3: 0.3;
$laptop: 1440px;
$notebook: 1200px;
$tablet: 768px;
$mobile: 545px;
$smallest: 350px;
$mobile: 545px;
$ipad: 991px;
// @font-face {
//   font-family: 'CreditCardFont';
//   src: url('./fonts/CREDC___.ttf');
// }

@mixin laptop {
  @media (max-width: #{$laptop}) {
    @content;
  }
}

@mixin notebook {
  @media (max-width: #{$notebook}) {
    @content;
  }
}

@mixin ipad {
  @media (max-width: #{$ipad}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

body {
  font-family: $lato;
  background: url("./../assets/body-background.jpg") 0 / cover fixed;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: white;
  &::-webkit-scrollbar {
    display: none;
  }
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: transparent !important;
  background: transparent !important;
  background-image: none !important;
  color: rgb(255, 255, 255) !important;
}
a {
  text-decoration: none !important;
  color: #fff;
}

hr {
  background: #fff;
}

.clear {
  clear: both;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  font-family: $lato;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  font-family: $lato;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  font-family: $lato;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  font-family: $lato;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin trans {
  transition: all 0.3s ease;
}

@mixin trans4 {
  transition: all 0.4s ease;
}

@mixin dark-blur {
  .dark-blur {
    position: absolute;
    padding: 10px 5px;
    // background: hsla(0,0%,100%,.3);
    // background: hsla(40%,0%,50%,.3);
    // background: rgba(0, 0, 0, 0.3);
    background: rgba(0, 0, 0, 0.1);
    font-size: 20px;
    font-family: "Lora", serif;
    line-height: 1.5;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    /* box-shadow: 5px 3px 30px black; */
    overflow: hidden;
  }

  .dark-blur::before {
    content: "";
    margin: -35px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*filter: blur(20px);*/
    filter: blur(10px);
    z-index: -1;
  }
  .dark-blur::before {
    background: url("./../assets/body-background.jpg") 0 / cover fixed;
  }
}
.relative {
  position: relative;
}

@mixin white-blur {
  .white-blur::before {
    background: url("./../assets/body-background.jpg") 0 / cover fixed;
  }
  .white-blur {
    position: absolute;
    padding: 10px 5px;
    background: hsla(0, 0%, 100%, 0.3);
    font-size: 20px;
    font-family: "Lora", serif;
    line-height: 1.5;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    /* box-shadow: 5px 3px 30px black; */
    overflow: hidden;
  }

  .white-blur::before {
    content: "";
    margin: -35px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(20px);
    z-index: -1;
  }
}

@mixin checkbox {
  .squaredThree {
    width: 20px;
    position: relative;
    margin: 20px auto;
    label {
      width: 16px;
      height: 16px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0;
      // box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,.4);
      border: 1px solid #fff;
      &:after {
        content: "";
        width: 9px;
        height: 5px;
        position: absolute;
        top: 4px;
        left: 4px;
        border: 3px solid #fcfff4;
        border-top: none;
        border-right: none;
        background: transparent;
        opacity: 0;
        transform: rotate(-45deg);
      }
      &:hover::after {
        opacity: 0.3;
      }
      p {
        width: 100%;
        margin-left: 30px;
      }
    }
    input[type="checkbox"] {
      visibility: hidden;
      &:checked + label:after {
        opacity: 1;
      }
    }
  }
}
@mixin button-round {
  border: 1px solid #fff;
  padding: 5px 15px;
  // min-width:  160px;
  text-align: center;
  color: #fff;
  background: transparent;
  margin: auto;
  border-radius: 5px;
  @include trans;
  &:hover {
    border-color: $blue-hover;
    color: $blue-hover;
  }
}
@mixin button-danger {
  padding: 5px 15px 5px 15px;
  // width:  160px;
  text-align: center;
  color: #fff;
  margin: auto;
  border-radius: 5px;
  background: #b74343;
  margin-right: 20px;
  margin-left: 20px;
  outline: none !important;
  @include trans;
  &:hover {
    background: lighten(#b74343, 15%);
  }
}

@import "_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/mixins/grid-framework";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/grid";
@import "_login-page.scss";
@import "_border-radius.scss";
@import "_dashboard-main.scss";
@import "_dashboard-side.scss";
@import "_orders-page.scss";
@import "_customers-page.scss";
@import "_card-management.scss";
@import "_analytics.scss";
@import "_bootstrap-datetimepicker.scss";
// @import 'bootstrap/bootstrap-datetimepicker.css';
@import "_elements.scss";
@import "_chat_module.scss";

/* BREADCRUMBS */
.breadcrumb {
  transition: all 0.3s ease-in-out;
  margin-left: 220px;
  &.small {
    margin-left: 0;
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  font-size: 14px;
  &::before {
    color: #fff;
  }
}
.breadcrumb-item {
  text-decoration: underline !important;
  font-family: $lato;
  &:first-child {
    padding-left: 50px;
  }
}

.breadcrumb-item.active {
  color: #fff;
  text-decoration: none !important;
  &::before {
    color: #fff;
  }
}

.sort {
  &::after {
    content: "\F0DC";
    font-family: FontAwesome;
    color: #fff;
    padding-left: 5px;
  }
}

.administrators {
  width: 260px;
  height: auto;
  position: absolute;
  top: 50px;
  right: 25px;
  p {
    color: #fff;
    text-align: right;
    span {
      color: #00ff00;
    }
  }
  .holder {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      text-align: center;
      margin: auto;
      img {
        margin-bottom: 10px;
      }
      p {
        span {
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background: #00ff00;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/* PAGINATION */
nav.pagination {
  margin: 10px 15px 10px 0 !important;
}
.page-item {
  margin-left: 10px;
}
.page-link {
  background: transparent;
  color: #fff;
  @include trans;
  &:hover {
    color: $blue-hover;
  }
}
#myChart-wrapper {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 100%;
}
.width-240 {
  width: 240px;
}
.width-180 {
  width: 180px;
}
.box {
  position: relative;
  width: 100%;
  // background: $light-brown;

  .box-title {
    margin: 0;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    font-size: 22px;
  }
}
.box-header-action {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translatey(-50%);
}
.box-header {
  max-width: 100%;
  position: relative;
  background: #59616b;
  margin: 0;
  // margin: 0 0 15px 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  // height: 100%;
  h2 {
    font-size: 22px;
    color: #fff;
    margin: 0;
    font-family: $lato;
    font-weight: 300;
  }
  &.no-bottom-margin {
    margin-bottom: 0 !important;
  }
  &.bottom-border {
    border-bottom: 1px solid #3e444a;
  }
  &.dark {
    background-color: #26292b;
  }
  .header-actions {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
  }
}
.box-body {
  position: relative;
  @include dark-blur;

  &.padded {
    padding: 20px;
  }
}
.select-holder .select2-container {
  min-width: 200px;
  min-height: 30px;
  margin-top: 10px;
}
.select-holder .select2-container--default .select2-selection--single {
  background: transparent;
  border: 1px solid #aaa;
}
.select-holder .select2-container--default .select2-selection--single .select2-selection__rendered {
  background: transparent;
}

input[type="search"],
.filter-field input {
  width: 100%;
  height: 35px;
  outline: none;
  border: none;
  position: relative;
  background: rgba(255, 255, 255, 0.6);
  color: #fff;
  padding: 5px;
}
input[type="search"] {
  background-image: url("./../assets/search.png");
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: center;
  background-size: 17px;
  padding-left: 35px;
  padding-right: 30px;
}
.box-footer {
  text-align: right;
  padding: 20px;
  @include dark-blur;
}
.pretty .state label {
  color: #fff;
}
.pretty.p-default input:checked ~ .state label:after {
  background-color: #fff !important;
}
.order-items {
  tr {
    cursor: pointer;
    td {
      @include trans;
    }
    &:first-child {
      &:hover {
        td {
          background: transparent !important;
        }
      }
    }
    &:hover {
      td {
        background: #6b6f73;
      }
    }
  }
}
table.table {
  width: 100%;
  position: relative;
  // min-height: 300px;
  tbody {
    tr {
      height: 50px;
      background: $light-brown;
      margin-bottom: 2px;
      border: none;
      // display: flex;
      border-bottom: 1px solid #3e444a;
      &.tr-no-height {
        height: auto;
      }
      &.tr-dark-bg {
        background: #3e444a !important;
        border: none;

        .app-table-sort-link {
          &:hover {
            color: white !important;
            text-decoration: underline !important;
          }

          &:focus {
            color: white !important;
          }
        }
      }
      &.tr-no-bg {
        background: transparent !important;
      }

      td {
        color: #fff;
        border: none;
        padding: 5px !important;
        .sorter {
          content: "\F0DC";
          font-family: FontAwesome;
          margin-left: 5px;
        }
      }
    }
  }
  &.table-no-bg {
    tr {
      background-color: transparent !important;
      border-bottom: 1px solid white;
      font-weight: bold;

      &:first-child {
        border-bottom: 2px solid white;
      }

      td {
        background-color: transparent;
        font-weight: normal;
      }
    }
  }
}

td {
  // padding: 0 0 0 30px !important;
  vertical-align: middle !important;
  color: #fff;
  border: none !important;
  @include laptop {
    // padding: 0 !important;
  }
}
@media (max-width: 1800px) {
  td {
    padding-left: 10px !important;
  }

  .condensed-table {
    td {
      font-size: 0.9em;
    }
  }
}
@include notebook {
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  label.analitycs {
    margin-left: 0;
  }
}
.search {
  position: absolute;
  right: 160px;
  top: 6px;
  input[type="text"] {
    position: relative;
    background: transparent;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 30px;
    outline: none;
    color: #fff;
    background-image: url("./../assets/search.png");
    background-repeat: no-repeat;
    background-position-x: 10px;
    background-position-y: center;
    background-size: 12px;
  }
}

.dashboard-menu-top {
  width: calc(100% - 260px);
  margin-left: 260px;
  height: 40px;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  &.fullsize {
    width: 100%;
    margin-left: 0;
    .left {
      left: 65px;
    }
  }
  // background: rgba($brown2, 0.5);
  @include dark-blur;
  .dark-blur {
    background: rgba(#000, $opacity3);
    border-radius: 0;
  }
  position: relative;
  #notificatons-holder {
    position: absolute;
    right: 150px;
    color: #fff;
    padding-top: 9px;
    height: auto;
    max-width: 280px;
    width: 100%;

    &:hover {
      .notifications {
        opacity: 1;
      }
    }

    .badge {
      position: absolute;
      top: 5px;
      left: 10px;
      min-width: auto !important;
    }

    .notifications {
      @include dark-blur;
      width: 250px;
      position: absolute;
      top: 7px;
      left: 0;
      left: 50%;
      margin-left: -225px;
      z-index: 100;
      display: none;
      border: 1px solid #ccc;
      ul {
        width: 100%;
        padding: 10px;
        margin: 0;
        list-style: none;
        position: relative;
        li {
          display: block;
          width: 100%;
          border-bottom: 1px solid white;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
            border-bottom: none;
          }

          .notification-actions {
            text-align: right;
          }
          a {
            padding: 10px 0 10px 0;
            // border-bottom: 1px solid #ccc;
            color: $blue-hover;
            @include trans;
            opacity: 0.9;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .left {
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translate(0, -50%);
    @include mobile {
      left: 20px;
    }
  }
  a.right {
    color: #fff !important;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    @include trans;
    &::after {
      content: "\f0d7 ";
      font-family: FontAwesome;
      color: $blue-hover;
      margin-left: 5px;
    }
    &:hover {
      color: #fff;
    }
  }
}

.superadmin-dropdown {
  @include dark-blur;
  width: 200px;
  height: auto;
  background: #fff;
  position: absolute;
  right: 0;
  top: 34px;
  z-index: 9999 !important;
  border-radius: 4px;
  @include trans;
  > div {
    padding: 20px 20px 20px 30px;
    @include trans;

    a {
      position: relative;
      display: block;
      font-size: 14px;
      margin-bottom: 10px;
      @include trans;
      i {
        margin-right: 5px;
      }
      &:hover {
        // color: white;
      }
    }
  }
}

.dashboard {
  // display: grid;
  // grid-template-columns: 260px 1fr;
  // grid-gap: 20px;
  display: block;
  @include trans;
  &.full-width {
    width: 100%;
  }
}
.side-menu-closed {
  // display: grid;
  // grid-template-columns: 40px 1fr;
  // grid-gap: 20px;
  @include trans;
}

ul.dashboard-management-nav {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #fff;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    position: relative;
    margin: 0 auto;
    max-width: 14.2%;
    flex: 1;
    text-align: center;
    padding: 5px;
    border: 1px solid transparent;
    border-bottom: none;
    @include trans;
    @include laptop {
      max-width: 20%;
    }
    a {
      color: #fff;
      @include trans;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        color: $blue-hover;
      }
      &:focus {
        color: #fff;
      }
    }
  }

  .active {
    border-color: #fff;
    border-bottom: 0;
  }
  .inactive {
    opacity: 0.5;
    // cursor: not-allowed;
    a {
      cursor: not-allowed;
      &:hover {
        color: #fff;
      }
    }
  }
}

.dashboard-management {
  // margin-right: 25px;

  nav {
    margin-bottom: 10px;
  }

  h1 {
    color: #fff;
    font-family: $lato;
    font-size: 34px;
    font-weight: 300;
    margin-top: 25px;
    margin-bottom: 20px;
    i {
      // margin-left: 30px;
    }
  }
  .inner-single-col {
    display: grid;
    //padding-top: 25px;
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }
  .inner {
    display: grid;
    //padding-top: 25px;
    // grid-template-columns: 380px 1fr;
    grid-template-columns: 33.481% 1fr;
    grid-gap: 25px;
    @include laptop {
      grid-template-columns: 1fr;
      .orders-list {
        grid-row: 2;
        min-height: auto;
        height: auto;
      }
      .main {
        min-height: auto;
      }
    }
  }
}
.orders-list {
  //  SIDE
  position: relative;
  @include dark-blur;
  height: 80vh;
  .top {
    background: #59616b;
    height: 50px;
    position: relative;
    margin: 2px 2px 15px 2px;
    h2 {
      font-family: $lato;
      font-size: 22px;
      color: #fff;
      font-weight: 300;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 10px;
    }
  }
  form {
    position: relative;
    width: 100%;
    padding: 0 20px 0 20px;
    input[type="search"] {
      width: 100%;
      height: 30px;
      outline: none;
      border: none;
      position: relative;
      background: rgba(255, 255, 255, 0.6);
      padding-left: 30px;
      padding-right: 30px;
      color: #fff;
    }
    .fa-search {
      position: absolute;
      color: #fff;
      top: 6px;
      left: 30px;
    }
    .fa-times {
      position: absolute;
      color: #fff;
      top: 6px;
      right: 30px;
      cursor: pointer;
    }
  }
  ul.orders-nav {
    position: relative;
    display: flex;
    padding: 0;
    margin: 15px 0 0 0;
    list-style: none;
    li {
      color: #fff;
      font-family: $lato;
      &:first-child {
        &::after {
          color: $blue-hover;
        }
      }
      &::after {
        content: "\f0dc";
        font-family: FontAwesome;
        color: #fff;
        padding-left: 5px;
      }
    }
  }
  .list-of-orders {
    position: relative;
    max-width: 100%;
    margin: 0 2px 0 2px;
    background: #59616b;
    max-height: 100%;
    height: max-content;
    ul {
      margin: 0;
      padding: 0;
      li {
        width: 100%;
        display: flex;
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #343434;
        @include trans;
        cursor: pointer;
        &:hover {
          background: #6b6f73;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
.main {
  @include dark-blur;
  position: relative;
  // min-height: 80vh;
}

.info-box {
  background: $light-brown;
  display: flex;
  margin: 10px 0;
  color: white;
  @include laptop {
    min-height: 160px;
  }
  @include ipad {
    min-height: 200px;
  }
  &.blue {
    .info-box-icon {
      background: $blue;
    }
  }

  &.red {
    .info-box-icon {
      background: $red;
    }
  }

  &.yellow {
    .info-box-icon {
      background: $yellow;
    }
  }

  &.green {
    .info-box-icon {
      background: $green;
    }
  }

  .info-box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    background: $dark;
    font-size: 2.5rem;
    text-align: center;
  }

  .info-box-content {
    display: inline-block;
    padding: 10px;

    .large-text {
      font-size: 2rem;
    }
  }
}

.header-filters {
  padding: 10px;
  background: rgba(0, 0, 0, 0.4);
  .header-filter {
    margin-left: 20px;
    display: inline-block;
    position: relative;
    &:nth-child(1) {
      margin-left: 0;
    }
    .select2-container {
      position: relative !important;
      transform: none;
    }
    span {
      color: white;
    }
  }
}
.select-with-icon {
  display: flex;
  min-height: 50px;
  .select-inner {
    min-width: 90%;
    position: relative;
    margin: auto;
  }
}
.selected-metrics {
  margin: 10px;
  .single-metric {
    margin-bottom: 20px;
    .metric-color {
      height: 20px;
      width: 40px;
      background: #b74343;
      display: inline-block;
      border-radius: 4px;
      margin: 0 15px;
      vertical-align: bottom;
    }
    .metric-name {
      line-height: 20px;
      border-radius: 4px;
      margin-right: 15px;
    }
    .metric-tags {
      display: inline-block;
    }
    .btn-remove-metric {
      margin-left: 30px;
      &:hover {
        color: $blue-hover;
      }
    }
  }
  .tag {
    padding: 3px;
    border: 1px solid white;
    border-radius: 3px;
    margin: 0 3px;
  }
}

.sub-nav {
  .sub-nav {
    margin-left: 10% !important;
    width: 90% !important;
  }
}

/* SEARCH PAGE */
.search-holder {
  position: relative;
  width: 100%;
  padding: 30px;
  .search-results {
    width: 100%;
    margin: 0;
    padding: 20px 0 20px 0;
    list-style: none;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: none;
    }
    li {
      color: #fff;
    }
  }
  .search-keyword {
    padding: 3px;
    background: #00aeef;
  }
}
/* SHIPPING PAGE */

.blur-holder {
  @include dark-blur;
}

/* TERMS AND CONDITIONS */

.terms-short-desc {
  max-width: 80%;
  margin: auto;
  margin-left: 0;
}
.flex-button-holder {
  display: flex;
  justify-content: flex-end;
}
//school-form
.school-form-holder {
  display: flex;
  width: 100%;
  margin: 0 auto 20px;
}
.column {
  flex-direction: column;
}
.school-title {
  color: #fff;
  text-align: center;
  margin: 20px auto;
  span {
    margin-top: 5px;
    display: block;
    font-size: 14px;
  }
}
.title-border-bottom {
  border-bottom: 1px solid #ccc;
  margin: 50px auto 20px;
  padding-bottom: 10px;
}
.theme-picker {
  > div {
    margin: 0 auto;
    &.active {
      img {
        border-radius: 8px;
        border: 1px solid #ccc;
      }
      p {
        color: #ccc;
      }
    }
    img {
      padding: 10px;
    }
  }
}

.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .circle-progress {
    color: white;
  }
}
.input-group {
  padding: 0 0 20px 0;
  display: block;
}
.ql-snow .ql-tooltip input[type="text"] {
  color: black !important;
}

.validation-message {
  color: #b74343;
  position: absolute;
  bottom: 8px;
  left: 0;
  font-size: 12px;
  // background: white;
  border-radius: 3px;
  padding: 0 5px;
}
.select-validation-message {
  color: #b74343;
  position: absolute;
  bottom: -20px;
  left: 0;
  font-size: 12px;
  // background: white;
  border-radius: 3px;
  padding: 0 5px;
}

.css-1wy0on6 {
  cursor: pointer !important;
}
.css-1ep9fjw:hover {
  color: #0275d8 !important;
}
.cancelBtn {
  border: 1px solid white;
  border-radius: 5px;
  margin-left: 20px;
  padding: 2px 30px;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid #00aeef;
    color: #00aeef !important;
  }
  &:focus {
    color: white !important;
  }
}
#school-profile-photo,
#school-cover-photo {
  display: none;
}
.pin {
  width: 30px;
  height: 30px;
  background: #b74343;
  border-radius: 100%;
}
.markerPin {
  width: 30px;
  height: auto;
}
.panNumber {
  // font-family: 'CreditCardFont', sans-serif;
  position: absolute;
  font-size: 16px;
  left: 10%;
  bottom: 25%;
}
.lockCardWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch-container {
  position: relative;
  display: flex;
  align-items: center;
}

input[type="checkbox"].switch {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: #b74343;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
  width: 47px;
  background-position: 0 0;
  background-color: #7bbacd;
}

input[type="checkbox"].switch + div {
  width: 47px;
  height: 24px;
}

input[type="checkbox"].switch:checked + div {
  background-color: #7bbacd;
}

input[type="checkbox"].switch + div > div {
  float: left;
  width: 24px;
  height: 24px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
  background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
  width: 22px;
  height: 22px;
  margin-top: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(23px, 0, 0);
  transform: translate3d(23px, 0, 0);
}
.cardHolder {
  padding: 0 !important;
}
.lockedOverlay {
  width: 100%;
  height: 100%;
  max-width: 500px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.push {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 40px;
  form {
    width: 500px;
    input {
      display: block;
      width: 100%;
      background: transparent;
      border: 1px solid white;
      outline: none;
      color: white;
      padding-left: 10px;
    }
    label {
      margin-top: 20px;
      margin-bottom: 10px;
      color: white;
    }
    textarea {
      width: 100%;
      background: transparent;
      display: block;
      border: 1px solid white;
      outline: none;
      color: white;
      padding: 10px;
    }
    button {
      margin: 30px auto;
      display: block;
    }
  }
}

.quill .ql-toolbar.ql-snow {
  background-color: white !important;
}
.ql-container.ql-snow {
  min-height: 300px;
}
.disabledProject {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  pointer-events: none;
  h1 {
    color: red;
    font-size: 60px;
    font-weight: bold;
    transform: rotate(-20deg);
  }
}
.badge {
  min-width: 100px !important;
}
.userCardLink {
  color: #a3ccff;
  &:hover {
    color: #82a0c5;
  }
}

.dark-well {
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  border: 1px solid white;
}

.table-word-break td {
  // word-break: break-all;
}

/* Change the white to any color ;) */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: rgba(255, 255, 255, 1) !important;
  font-family: $lato !important;
}

input[value=""]::-webkit-datetime-edit {
  color: transparent;
}
input:focus::-webkit-datetime-edit {
  color: #fff;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 999999 !important;
}
.roles-first-column {
  border-right: 1px solid white;
}

.permission-title {
  margin-top: 1rem;
}
.roles-second-column {
  min-height: 375px !important;
  padding-bottom: 60px !important;
  position: relative;
}
.permissions-holder {
  border: 1px solid white;
  padding: 10px;
  flex: 1;
  min-height: 300px;
  p {
    margin: 0;
  }
  .allKyc-wrapper {
    background-color: gray;
    margin: 10px 0;
    padding: 10px;
  }
  .allKyc-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .allKyc-child {
      width: 100%;
      text-align: center;
      padding: 0 50px;
    }
  }
}

.add-roles-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 550px;
  width: 100%;
  margin: auto;
  padding-bottom: 50px;
  .roles-btn {
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px 30px;
    text-decoration: none !important;
    &:hover {
      border: 1px solid #459cb7;
      color: #459cb7 !important;
    }
  }

  .acitve-btn {
    border: 1px solid #00aeef;
    color: #00aeef !important;
  }
}

.perm-holder {
  border: 1px solid white;
  min-height: 300px;
  padding: 10px 10px 60px 10px;
  border-radius: 5px;
  position: relative;
  margin-top: 20px;
}
.added-perm-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 10px 0;
  padding: 10px;
}
.added-role-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 10px 0;
  padding: 10px;
  .first-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .second-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 5px 0;
    padding: 10px 0;
  }
  .allow-deny {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    span {
      border-bottom: 1px solid white;
      width: 50%;
      padding-bottom: 5px;
      text-align: center;
    }
  }
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}
.css-b8ldur-Input {
  color: white !important;
}

.apple-upload-btn-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-upload-btn-holder {
  position: relative;
  text-align: center;
  margin-bottom: 20px;

  .validation-message {
    text-align: center;
    width: 100%;
    position: relative;
    bottom: auto;
  }

  .file-preview {
    max-width: 100%;
    word-wrap: break-word;

    div {
      margin: auto;
    }
  }
}

.add-note {
  border: 1px solid white;
  .add-note-header {
    padding: 5px 15px;
    border-bottom: 1px solid white;
    p {
      margin: 0;
    }
  }
}